/** @format */

import Decimal from "decimal.js";
import {
  setRecordHistory,
  setTypeData,
  setOutTypeData,
  setUserInfo,
  userInfo,
  typeData,
  outTypeData,
} from "../store/global.data";
import { AcrRecord, UserInfo } from "../store/global.data.type";
import { allTypeMap, setAllTypeMap } from "../store/global.filter";
import { buildTree, updStringToDecimal } from "../utils/utils.array";
import {
  clearAllLocalStorage,
  setLocAllTypeMap,
  setLocOutType,
  setLocPutType,
  setLocRecordList,
  setLocUserInfo,
} from "../utils/utils.localstroage";
import { service } from "../utils/utils.request";
import { CostType } from "../views/home/home.type";
import { produce } from "solid-js/store";
import { appLogOut } from "./app.logic";
import { getTodayStartAndEnd } from "../utils/utils.time";

// /**
//  * 用户登录后更新所有价格 用户的所有消费总和
//  */
// export function updALllPrice() {
//   service({
//     url: "/ab/g-o",
//     method: "GET",
//     cache: true,
//   }).then((res) => {
//     // if (res.status !== 200) return;
//     const arr = JSON.parse(res.data);
//     //////console.log("用户的所有消费总和", arr);

//     for (const element of arr) {
//       //   const [fArr, set] = getArrayAndSetFunction(element.type);
//       //   const index = fArr.findIndex((ele) => ele.id === element.oneId);

//       setAllTypeMap(
//         element.id,
//         produce((items) => {
//           items.money = new Decimal(element.money);
//         })
//       );
//       //   if (index !== -1) {
//       //     set([index], "money", element.money);
//       //   }
//     }
//   });
// }

interface GetUserInfo {
  succeed?: Function;
  fail?: Function;
}
/**
 * 获取用户信息
 * @param opt
 */
export async function getUserInfo(opt?: GetUserInfo): Promise<any> {
  const res = await service({
    url: "/ab/g-i",
    method: "GET",
    cache: true,
  });

  if (res.status == 200) {
    const uif = JSON.parse(res.data);
    if (uif?.walletList) {
      uif.walletList = updStringToDecimal(uif.walletList);
    }
    if (uif.monthTypeMoney) {
      const adObj: any = {};
      Object.keys(uif.monthTypeMoney).forEach((key) => {
        const item = uif.monthTypeMoney[key];
        item.money = new Decimal(item.money);
        adObj[item.id] = item;
      });
      uif.monthTypeMoney = adObj;
    }

    setUserInfo((val) => {
      // console.log("????????????", val);
      return {
        ...val,
        ...uif,
      };
    });
    setLocUserInfo(userInfo);
    console.log("用户信息1", userInfo);
    if (opt?.succeed) {
      opt.succeed(uif);
    }
    return uif;
  } else if (opt?.fail) {
    opt.fail(res);
  }
  appLogOut();
  //   //////////console.log("==============", userInfo);
  setRecordHistory([]);
  return null;
}

/**
 * 默认获取当日的记账记录
 * @param opt
 */
export function getRecordList(
  opt: any = {
    skip: 1,
    take: 20,
    time: getTodayStartAndEnd(),
  }
) {
  service({
    url: "/ab/g-ar",
    method: "GET",
    cache: true,
    params: {
      p: JSON.stringify(opt),
    },
  }).then((res) => {
    console.log("?????????", res);

    if (res.status == 200) {
      let data = JSON.parse(res.data) as AcrRecord[];
      data = updStringToDecimal(data);
      setRecordHistory(data);
      setLocRecordList(data);
      console.log("当日记录更新", JSON.parse(res.data));
    }
  });
}

/**
 * 获取所有类型
 */
export function getAllType() {
  service({
    url: "/ab/g-o-t",
    method: "GET",
    cache: true,
  }).then((res) => {
    if (res.status == 200) {
      const data = JSON.parse(res.data) as CostType[];
      // console.log("----",data);

      const { arr, map } = buildTree(data);
      setAllTypeMap({
        ...allTypeMap,
        ...map,
      });
      const outArr: CostType[] = [];
      const putArr: CostType[] = [];

      // 计算各个元素的位置
      const colNum = 5;
      const gap = 5;
      const smPad = 8;
      const itw = (window.innerWidth - gap * (colNum - 1) - smPad * 2) / colNum;
      arr.filter((it) => {
        it.itw = itw;
        it.style = {
          width: itw + "px",
          height: itw + "px",
          // position: "absolute",
        };
        if (it.type == 2) {
          outArr.push(it);
        } else {
          putArr.push(it);
        }
      });
      setTypeData(putArr);
      setTypeData(
        produce((items) => {
          items.filter((it, inx) => {
            if (inx % colNum == 0) {
            } else {
              // it.style.left = `${smPad}px`;
            }

            const left = smPad + itw * inx + inx * gap;
            // it.style.left = `${left}px`;
            // it.style.top = `${left}px`;
          });
        })
      );
      setOutTypeData(outArr);
      setLocPutType(putArr);
      setLocOutType(outArr);
      setLocAllTypeMap(allTypeMap);

      // console.log("所有类型map", allTypeMap);
      console.log("所有支出类型", typeData);
      console.log("所有收入类型", outTypeData);
    }
  });
}
