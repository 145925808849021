import { Component, createSignal } from "solid-js";
import "./updActType.scss";
import { service } from "../../../utils/utils.request";
import { creToast, domById } from "../../../utils/utils.html";
import { setAllTypeMap } from "../../../store/global.filter";
import { produce } from "solid-js/store";
import { selOneType, userInfo } from "../../../store/global.data";
import { CostType } from "../../home/home.type";

export interface UpdActTypeInt {
  /**
   * 父级分类
   */
  plant: CostType;

  /**
   * 成功回调
   */
  success: Function;
}
export const UpdActType: Component<UpdActTypeInt> = (props) => {
  //   二级分类的支出也写明
  //   const { val } = selOneType;

  //----------------------------------------------------------------------------------- 新增分类
  let updActTypeDialog: HTMLDialogElement | null;
  const [updAtIng, setupdAtIng] = createSignal(false);
  /**
   * 新增二级分类
   */
  async function updActTypeSubmit() {
    if (updAtIng()) return;
    setupdAtIng(true);
    const nameInput = domById<HTMLInputElement>(
      "#actTypeName",
      updActTypeDialog
    )?.value;
    const beforeInput = domById<HTMLInputElement>(
      "#beforeInput",
      updActTypeDialog
    )?.value;

    //console.log("nameInput", beforeInput, nameInput);

    if (!nameInput) {
      setupdAtIng(false);
      return;
    }
    const { plant } = props;
    const updRes = await service({
      url: "/ab/c-at",
      method: "POST",
      data: JSON.stringify({
        before: beforeInput?.trim() || null,
        name: nameInput.trim(),
        type: plant.type,
        pId: plant.id ? plant.id : null,
      }),
    });
    //console.log("====", updRes);
    if (updRes.status == 201) {
      creToast({ message: "成功", type: "success" });
      props.success(updRes);
    } else {
      creToast({ message: updRes.data, type: "error" });
    }

    setupdAtIng(false);
    updActTypeDialog?.close();
  }

  return (
    <>
      <button class="btn" on:click={() => updActTypeDialog?.showModal()}>
        +
      </button>
      <dialog class="modal" ref={(el) => (updActTypeDialog = el)}>
        <div class="modal-box">
          <form method="dialog">
            <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <h3 class="font-bold text-lg">新增类型</h3>
          <div class="uatd_v">
            <input
              type="text"
              placeholder="前置描述"
              class="input input-bordered  w-full"
              id="beforeInput"
            />
            <input
              type="text"
              placeholder="名称"
              class="input input-bordered  w-full"
              id="actTypeName"
            />
            <div class="mb-rerow">
              <button
                class="btn btn-primary"
                onclick={updActTypeSubmit.bind(null)}
              >
                {updAtIng() ? (
                  <span class="loading loading-ring loading-md"></span>
                ) : (
                  <span>确定</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </>
  );
};
