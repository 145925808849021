/** @format */

import {
  createSignal,
  createEffect,
  onMount,
  onCleanup,
  For,
  createMemo,
  Show,
  Component,
} from "solid-js";
import {
  setOutTypeData,
  setSelOneType,
  setTypeData,
  userInfo,
} from "../../../store/global.data";
import { produce } from "solid-js/store";
import Decimal from "decimal.js";
import { CostType } from "../home.type";
import { useNavigate } from "@solidjs/router";
import { getSubTypeAllMoney } from "../../../utils/utils.object";
import { UpdActType } from "../../record/components/updActType";
import { setAllTypeMap } from "../../../store/global.filter";

interface ActTypeProps {
  typArr: CostType[];
  type: 1 | 2;
  set: Function;
}

export const ActType: Component<ActTypeProps> = (props) => {
  const { typArr, type, set } = props;
  const navigate = useNavigate();
  const [containerElement, setContainerElement] =
    createSignal<HTMLElement | null>(null);

  let draggedIndex = -1;
  const handleClick = (it: CostType, inx: number) => {
    setSelOneType({
      val: it,
      inx,
    });
    navigate(`/record`);
  };
  let startX: number = 0,
    startY: number = 0;
  let offsetX: number = 0,
    offsetY: number = 0;
  /**
   * 克隆的元素
   */
  let cloneNode: any;
  /**
   * 实际元素
   */
  let controlNode: any;
  function handleTouchStart(event: TouchEvent, index: number) {
    return
    document.body.style.overflow = "hidden";
    controlNode = event.currentTarget!;
    controlNode.style.removeProperty("transition");
    draggedIndex = index;
    const touch = event.touches[0];
    startX = touch.clientX;
    startY = touch.clientY;
    offsetX = controlNode.offsetLeft;
    offsetY = controlNode.offsetTop;
    // 绝对定位
    cloneNode = controlNode.cloneNode(true);
    controlNode.style.opacity = 0;
    cloneNode.style.position = "absolute";
    cloneNode.style.left = offsetX + "px";
    cloneNode.style.top = offsetY + "px";
    cloneNode.style.zIndex = 999;
    cloneNode.style.transition = "none";
    cloneNode.style.pointerEvents = "none";
    containerElement()!.appendChild(cloneNode);
    console.log(startX, startY, event);
  }
  const handleTouchMove = (event: TouchEvent) => {
    if (cloneNode && controlNode) {
      event.preventDefault();
      const e = event.touches[0];
      requestAnimationFrame(() => {
        const ex = e.clientX - startX;
        const ey = e.clientY - startY;
        cloneNode.style.transform = `translate(${ex}px, ${ey}px) translate(0, 0)`;
      });

      // 获取目标位置下的元素
      const element: any = document.elementFromPoint(e.clientX, e.clientY);
      if (element && element.classList.contains("btn")) {
        const arr = Array.from(element.parentNode.children);
        const index = arr.indexOf(element);
        if (index !== draggedIndex) {
          const parentNode = element.parentNode;
          // 直接在 DOM 中重新排序
          // parentNode.removeChild(controlNode);
          if (index < parentNode.children.length) {
            parentNode.insertBefore(controlNode, parentNode.children[index]);
          } else {
            parentNode.appendChild(controlNode);
          }
          // controlNode.style.transform = `translate(${element.offsetLeft}px, ${element.offsetTop}px) translate(0, 0)`;
          // element.style.position = "absolute";
          // element.style.top = offsetY + "px";
          // element.style.left = offsetX + "px";
          // controlNode.style.zIndex = 999;

          console.log(
            "Element absolute position relative to parent:",
            element.offsetLeft,
            element.offsetTop
          );
          draggedIndex = index;
        }
      }
    }
  };

  const handleTouchEnd = () => {
    if (cloneNode && controlNode) {
      cloneNode.remove();
      controlNode.style.removeProperty("opacity");
      cloneNode = null;
      controlNode = null;
      document.body.style.removeProperty("overflow");
    }
  };

  onMount(() => {
    const container = containerElement();
    if (container) {
      container.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    }
    return () => {
      if (container) {
        container.removeEventListener("touchmove", handleTouchMove);
      }
    };
  });

  function updSuccess(updRes: any) {
    const val = JSON.parse(updRes.data);
    val.children = [];
    setAllTypeMap(val.id, val);
    set( produce((items: any) => {
      val.sumMoney = createMemo<Decimal>(
        (): Decimal => getSubTypeAllMoney(val.id)
      );
      items.push(val);
    }))
  }
  return (
    <>
      <div
        class="m_cont"
        ref={(el) => setContainerElement(el)}
        onTouchEnd={handleTouchEnd}
      >
        <For each={typArr} fallback={<div>Loading...</div>}>
          {(item: CostType, index) => {
            set(
              [index()],
              produce((items: any) => {
                items.sumMoney = createMemo<Decimal>(
                  (): Decimal => getSubTypeAllMoney(item.id)
                );
              })
            );
            return (
              <button
                class={`btn it${index() + 1}`}
                onClick={handleClick.bind(null, item, index())}
                onTouchStart={(event) => handleTouchStart(event, index())}
                style={item.style}
              >
                {item.name}
                <Show when={item.sumMoney}>
                  {/* @ts-ignore */}
                  <Show when={!item.sumMoney().isZero()}>
                    {/* @ts-ignore */}
                    <p class="bt_m">{item.sumMoney().toString()}</p>
                  </Show>
                </Show>
              </button>
            );
          }}
        </For>
        {/* @ts-ignore */}
        <UpdActType plant={{ type: type }} success={updSuccess}></UpdActType>
      </div>
    </>
  );
};
