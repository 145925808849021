/** @format */

import {
  createMemo,
  createSignal,
  For,
  onCleanup,
  onMount,
  Show,
} from "solid-js";
import "./record.scss";
import {
  outTypeData,
  recordHistory,
  selOneType,
  setRecordHistory,
  setUserInfo,
  typeData,
  userInfo,
} from "../../store/global.data";
import { useNavigate } from "@solidjs/router";
import { CostType } from "../home/home.type";
import { service } from "../../utils/utils.request";
import { createStore, produce } from "solid-js/store";
import { creModal, domById, creToast } from "../../utils/utils.html";
import { getNewObject } from "../../utils/utils.object";
import Decimal from "decimal.js";
import {
  setLocOutType,
  setLocPutType,
  setLocRecordList,
  setLocUserInfo,
} from "../../utils/utils.localstroage";
import { PageHeader } from "../../components/pageHeader/pageHeader";
import { WalletList } from "../../components/walletList/walletList";
import { setAllTypeMap } from "../../store/global.filter";
import { UpdActType } from "./components/updActType";

const costType: CostType = {
  id: 0,
  name: "",
  // @ts-ignore
  type: 0,
  children: [],
};
/**
 * 记录页
 * @returns
 */
export function Record() {
  const navigate = useNavigate();

  //////////console.log("====", selOneType);
  const { val } = selOneType;

  if (!val.id) {
    navigate(-1);
  }

  onMount(() => {
    //////////console.log("本地用户信息", userInfo);

    if (!userInfo.id) {
      navigate(`/login`);
    }
  });
  /**
   * 输入的价格
   */
  const [formData, setFormData] = createStore({
    money: "",
    /**
     * 借用
     */
    loan: false,
    /**
     * 备注
     */
    remark: "",
    /**
     * 报销
     */
    rs: false,
    /**
     * 选中的钱包
     * 如果是支出，并且是借钱，那么这个就是借钱的钱包
     * 如果是收入，那么这个就是存钱的钱包
     */
    wId: 0,
    /**
     * 借钱钱包
     * 如果是收入的情况下，这个是借钱钱包
     */
    lwId: 0,
  });

  /**
   * 是否打开备注
   */
  const [showRkInp, setShowRkInp] = createSignal(false);

  /**
   * 当前选中的二级分类
   */
  const [nowTy, setNowTy] = createStore<CostType>(getNewObject(costType));

  /**
   * 账单类型
   */
  const moneyType = createMemo(() => {
    return (nowTy.type || val.type) == 1;
  });

  // /**
  //  * 当前选中的借钱钱包
  //  */
  // // @ts-ignore
  // const [selLoanWallet, setSelLoanWallet] = createStore<Wallet>({});

  // /**
  //  * 当前选中的存钱钱包
  //  */
  // // @ts-ignore
  // const [selWallet, setSelWallet] = createStore<Wallet>({});

  /**
   * 二级分类选择
   * @param inx
   * @returns
   */
  function seled(it: CostType) {
    if (nowTy.id == it.id) {
      setNowTy(getNewObject(costType));
      return;
    }
    setNowTy(it);
  }

  /**
   * 提交相关处理
   */
  const [cfWkIng, setCfWkIng] = createStore({
    wk: false,
  });

  let diyModal: null | HTMLDialogElement;
  /**
   * 提交函数
   * @returns
   */
  async function handleConfirm() {
    if (formData.money.length == 0) return;
    if (formData.loan && !formData.lwId && !moneyType()) {
      creToast({ message: "请选择借钱途径", type: "warning" });
      return;
    }
    if (cfWkIng.wk) return;
    setCfWkIng("wk", true);

    // 重复提醒
    const po = recordHistory[0] || {};
    if (po?.money?.toString() == formData.money && po?.verify != false) {
      if (!diyModal) {
        diyModal = creModal({
          id: "diyModel",
          title: "重复",
          content:
            "您最近有一笔重复的记录，确定要继续吗？如本次确认后需二次点击确认进行提交。",
          confirm: {
            text: "我确定",
            callback() {
              diyModal?.close();
              // pt[pt.length - 1].verify = false;
              setRecordHistory(
                [0],
                produce((items) => {
                  items.verify = false;
                })
              );
              setCfWkIng("wk", false);
            },
          },
          cancel: {
            callback() {
              setCfWkIng("wk", false);
            },
          },
        });
      } else {
        diyModal?.showModal();
      }
      return;
    }

    // const qMoney = new Decimal(
    //   `${moneyType() == 1 ? "-" : ""}` + formData.money
    // );
    const qMoney = new Decimal(formData.money);
    const da: any = {
      type: nowTy.type || val.type,
      oneId: val.id,
      twoId: nowTy.id,
      money: qMoney,
    };
    if (formData.wId) {
      da.wId = formData.wId;
    }
    if (formData.lwId) {
      da.lwId = formData.lwId;
    }

    if (formData.rs) {
      // @ts-ignore
      da.rs = 1;
    }
    if (formData.loan) {
      // @ts-ignore
      da.loan = 1;
    }
    if (formData.remark) {
      da.remark = formData.remark;
    }

    const qd = JSON.stringify(da);
    const ins = await service({
      url: "/ab/c-ar",
      method: "POST",
      data: qd,
    });
    if (ins.status == 201) {
      creToast({ message: "成功", type: "success" });
      const insDataArr = JSON.parse(ins.data);
      console.log("结果", insDataArr);
      for (let index = 0; index < insDataArr.length; index++) {
        const insData = insDataArr[index];
        insData.money = qMoney
        // 给相应分类添加金额
        let updId = insData.twoId || insData.oneId;
        setUserInfo(
          produce((uIf) => {
            const { wId, end } = insData;
            let inx = -1;
            const ins = uIf.walletList.find((it, inx) => {
              if (it.id == wId) {
                inx = inx;
                return true;
              }
            });
            if (ins) {
              ins.money = new Decimal(end);
              uIf.walletList[inx] = ins;
            }
            const tMon = uIf.monthTypeMoney[updId];
            if (tMon) {
              uIf.monthTypeMoney[updId].money = qMoney.add(tMon.money);
            } else {
              uIf.monthTypeMoney[updId] = {
                money: qMoney,
                type: insData.type,
                pId: insData.oneId,
                id: insData.id,
              };
            }
          })
        );
        setRecordHistory(
          produce((items) => {
            items.unshift(insData);
          })
        );
        console.log("更新结果", recordHistory);
      }
      setLocUserInfo(userInfo);
      setLocRecordList(recordHistory);
      // setLocAllTypeMap(allTypeMap);
    } else {
      creToast({ message: ins.data, type: "error" });
    }
    setCfWkIng("wk", false);
  }

  onMount(() => {
    domById<HTMLInputElement>("priceInput")?.focus();
  });
  //   使用 onCleanup 钩子 卸载时将数据存储至本地
  onCleanup(() => {
    // //////////console.log("Record component is unmounting");
    if (val.type == 1) {
      setLocPutType(typeData);
    } else {
      setLocOutType(outTypeData);
    }
    // 在这里执行你需要的操作，例如清理定时器、取消订阅等
  });

  // ---
  function updSuccess(updRes: any) {
    setAllTypeMap(
      val.id,
      produce((items) => {
        items.children.push(JSON.parse(updRes.data));
        items.children = items.children;
      })
    );

    // const [arr] = getArrayAndSetFunctionmoneyType();
    if (moneyType()) {
      setLocPutType(typeData);
    } else {
      setLocOutType(outTypeData);
    }
  }
  return (
    <>
      <PageHeader></PageHeader>
      <div class="rec_cont">
        <div class="tit_v">
          <div class="tv">
            <span class="tit">
              {nowTy.before || val.name}
              {nowTy.name}
            </span>
            <span onclick={() => setShowRkInp(!showRkInp())}>备注</span>
          </div>

          <button class="btn btn-primary" onClick={handleConfirm}>
            {cfWkIng.wk ? (
              <span class="loading loading-ring loading-md"></span>
            ) : (
              <span>确定</span>
            )}
          </button>
        </div>

        <input
          type="number"
          placeholder="人民币"
          class="input input-bordered input-lg w-full"
          onInput={(event: InputEvent) => {
            setFormData("money", (event.target as HTMLInputElement).value);
          }}
          id="priceInput"
        />

        <div class="tit_v">
          {/* @ts-ignore */}
          <span>{val.children?.length > 0 ? `选择细分或者就这样` : ""}</span>

          <div class="tit_v">
            {/* <Show when={val.type == 1}>
              <label class="label cursor-pointer">
                <span class="label-text">报销</span>
                <input
                  type="checkbox"
                  class="checkbox"
                  onChange={(event) => {
                    setFormData(
                      "rs",
                      (event.target as HTMLInputElement).checked
                    );
                  }}
                />
              </label>
            </Show> */}
            <Show when={nowTy.id != 61}>
              <label class="label cursor-pointer">
                <span class="label-text">借入</span>
                <input
                  type="checkbox"
                  class="checkbox"
                  checked={formData.loan}
                  onChange={(event) => {
                    setFormData(
                      "loan",
                      (event.target as HTMLInputElement).checked
                    );
                  }}
                />
              </label>
            </Show>
          </div>
        </div>

        <div class="m_cont">
          <Show when={val.children.length > 0}>
            <For each={val.children}>
              {(item: CostType, index) => (
                <button
                  class={`btn it${index() + 1} ${
                    item.id == nowTy.id ? "btn-primary" : ""
                  }`}
                  onClick={() => seled(item)}
                >
                  <div class="btn_txt">
                    {item.name}
                    <p class="bt_m">
                      {userInfo.monthTypeMoney[item.id]?.money.toString()}
                      {/* {JSON.stringify(userInfo.monthTypeMoney[item.id] || {})} */}
                    </p>
                  </div>
                </button>
              )}
            </For>
          </Show>
          <Show when={val.id != 43}>
            {/* <button
              class={`btn it0 `}
              onclick={() => {
                // @ts-ignore
                domById("updActTypeDialog")?.showModal();
              }}
            >
              <div class="btn_txt">+</div>
            </button> */}
            <UpdActType plant={val} success={updSuccess}></UpdActType>
          </Show>
        </div>

        {/* 钱包列表 */
        /* @ts-ignore */}
        <Show when={userInfo.walletList}>
          {/* 如果直接支出 需要选择钱包id */}
          {/* 如果借入后直接支出，需要选择借钱包id */}
          {/* 如果存入钱包，需要选择存入钱包id */}
          {/* 如果借钱存入钱包，这需要选择借钱包id */}
          {/* <Show when={moneyType() == 1 && !formData.loan}>
            
          </Show> */}
          <WalletList
            title="选择钱包"
            compare={!moneyType() ? "gte" : ""}
            selWalletRes={(res) => {
              if (moneyType()) {
                if (res.money.lt(0) && res.id) {
                  setFormData("loan", true);
                } else {
                  setFormData("loan", false);
                }
              }
              setFormData("wId", res.id);
            }}
          ></WalletList>

          <Show when={(formData.loan && !moneyType()) || nowTy.id == 61}>
            <WalletList
              title="选择借入渠道"
              compare="lte"
              selWalletRes={(res) => {
                setFormData("lwId", res.id);
              }}
            ></WalletList>
          </Show>
        </Show>

        <Show when={showRkInp() || val.id == 43}>
          <input
            type="text"
            placeholder={"备注"}
            class="input input-bordered input-lg w-full"
            onInput={(event: InputEvent) => {
              setFormData("remark", (event.target as HTMLInputElement).value);
            }}
            id="remarkInput"
          />
        </Show>
      </div>
    </>
  );
}
