/** @format */

import { createSignal, createMemo, Show } from "solid-js";
import "./home.scss";
import {
  gld,
  outTypeData,
  recordHistory,
  setOutTypeData,
  setTypeData,
  typeData,
  userInfo,
} from "../../store/global.data";
import Decimal from "decimal.js";
import { arrReduce, arrReduceTwo } from "../../utils/utils.array";
import { RecordList } from "../record/recordList";
import { formattedDate, timeZone } from "../../utils/utils.time";
import { ActType } from "./components/actType";
// import { WalletList } from "../../components/walletList/walletList";
// @ts-ignore
// import uni from "../../utils/hybrid_html_uni.webview.1.5.5.js";
import { navTo, router } from "../../utils/utils.router.js";
import { useNavigate } from "@solidjs/router";
import { setNavigationBarColor } from "../../utils/utils.app.js";
import { getRecordList } from "../../logic/global.api.js";

export function Home() {
  setNavigationBarColor("#ffffff");
  router.val = useNavigate();
  // // 接受app端发送的消息
  // // @ts-ignore
  // window.evalJsFun = (res: AppEvalJS) => {
  //   //console.log(res);
  //   // alert(JSON.stringify(res));
  //   // const data = JSON.parse(res);
  //   if (res.fun) {
  //     appEvalJS[res.fun](res.data);
  //   }
  // };

  // 向app发送消息
  // uni.webView.postMessage({
  //   data: {
  //     action: "message",
  //     data: window.location.hostname,
  //   },
  // });

  /**
   * 月支出总和
   */
  const putMoney = createMemo<Decimal>((): Decimal => {
    //console.log("??????", typeData);

    return arrReduce({
      arr: typeData,
    });
  });

  /**
   * 月收入总和
   */
  const outMoney = createMemo<Decimal>(
    (): Decimal =>
      arrReduce({
        arr: outTypeData,
      })
  );

  const dayPut = createMemo<Decimal>(
    (): Decimal =>
      arrReduceTwo({
        arr: recordHistory,
      })
  );

  /**
   * 抽屉开关状态
   */
  const [isChecked, setIsChecked] = createSignal(false);
  /**
   * 控制抽屉开关状态
   * @param event
   */
  const handleCheckboxChange = (event: Event) => {
    setIsChecked((event.target as HTMLInputElement).checked);
  };
  return (
    <>
      <div class="drawer">
        <input
          id="my-drawer"
          type="checkbox"
          class="drawer-toggle"
          checked={isChecked()}
          onChange={handleCheckboxChange}
        />
        <div class="drawer-content">
          <div
            class="header_v bg-primary"
            style={gld.sbh ? `padding-top:${gld.sbh}px` : "111"}
          >
            <div class="hv_it">
              <label class="btn btn-circle swap swap-rotate">
                <input
                  type="checkbox"
                  checked={isChecked()}
                  onChange={handleCheckboxChange}
                />
                <svg
                  class="swap-off fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 512 512"
                >
                  <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
                </svg>
                <svg
                  class="swap-on fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 512 512"
                >
                  <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
                </svg>
              </label>
              <div>{`${formattedDate(new Date(), "yyyy-MM-dd")}`}</div>
              {/* ${timeZone()} */}
            </div>

            <div class="info_it">
              <div class="it_col">
                <span>月支出</span>
                <span class="op_t">{putMoney().toNumber()}</span>
              </div>
              <div class="it_row">
                <span>月收入 {outMoney().toString()}</span>

                {/* 月结余：必须是月生于的持有，是正整数，需要剔除债务 */}
                {/* 月债务：这个月产生的债务 */}
                <span> 月结余 {outMoney().minus(putMoney()).toString()}</span>
                <div style="flex:1;"></div>
                <span>日支出{dayPut().toString()}</span>
              </div>
            </div>
          </div>

          <ActType type={1} typArr={typeData} set={setTypeData}></ActType>

          <div class="divider">收入</div>

          <ActType type={2} typArr={outTypeData} set={setOutTypeData}></ActType>
          {/* <WalletList></WalletList> */}

          <RecordList records={recordHistory} noStatus={true}></RecordList>
        </div>
        <div class="drawer-side ">
          <label
            for="my-drawer"
            aria-label="close sidebar"
            class="drawer-overlay"
          ></label>
          <ul
            class="menu text-base-content min-h-full w-60 p-4"
            style={gld.sbh ? `padding-top:${gld.sbh}px` : "111"}
          >
            <Show when={userInfo.id}>
              <div class="uif_v">
                <div class="avatar">
                  <div class="ring-primary ring-offset-base-100 w-16 rounded-full ring ring-offset-2">
                    <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" />
                  </div>
                </div>

                <div>{userInfo.account}</div>
              </div>
            </Show>

            <nav>
              {/* <li>
                <a href="/rc-li">记录</a>
              </li> */}
              <Show when={userInfo.id && userInfo.ps}>
                <li>
                  <a onclick={navTo.bind(null, "/p")}>资产</a>
                </li>
              </Show>
              <Show when={userInfo.id}>
                <li>
                  <a onclick={navTo.bind(null, "/billing")}>账单</a>
                </li>
              </Show>
              <li>
                <a onclick={navTo.bind(null, "/setting")}>设置</a>
              </li>
            </nav>
          </ul>
        </div>
      </div>

      <div class="btm-nav tb_v">
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span class="btm-nav-label">账单</span>
        </button>
        <button class="active">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
          <span class="btm-nav-label">主页</span>
        </button>
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
            />
          </svg>
          <span class="btm-nav-label">我的</span>
        </button>
      </div>
    </>
  );
}
