/** @format */

import { gld, setUserInfo } from "../../store/global.data";
import "./login.scss";
import { service } from "../../utils/utils.request";
// import { sm3 } from "sm-crypto-v2";
import { isPhoneNumber } from "../../utils/utils.string";
import { setLocUserInfo } from "../../utils/utils.localstroage";
import { useNavigate } from "@solidjs/router";
import { getRecordList } from "../../logic/global.api";
import { creToast, domById } from "../../utils/utils.html";
import { updStringToDecimal } from "../../utils/utils.array";
import Decimal from "decimal.js";
import { onMount } from "solid-js";

/**
 *
 * 登陆页
 * @returns
 */
export function Login() {
  // const [loginForm, setLoginForm] = createStore({
  //   account: "",
  // });

  //   let formId: HTMLDivElement;

  const nav = useNavigate();
  const loginForm = {
    account: "",
    deviceId: "",
  };

  //   onMount(() => {
  //     formId = domById('formId')
  //   });

  async function handleLogin() {
    // appLogin(loginForm);
    if (!isPhoneNumber(loginForm.account)) {
      return;
    }
    console.log("-=-=-=-=-=", loginForm);

    const query = {
      ...loginForm,
      //   deviceId: sm3(`${gld.brand || gld.model} ${gld.model} ${gld.language}`),
      client: 1,
      deviceBrand: gld.brand || gld.model,
      deviceModel: gld.model,
      language: gld.language,
      osName: gld.osName,
    };
    console.log("登陆 / 注册参数", query);
    const loginRes = await service({
      url: "/ab/lg",
      method: "POST",
      data: JSON.stringify(query),
    });
    const { status, data, headers } = loginRes;
    console.log("=== 登录 ", headers);

    if (status != 200) {
      creToast({
        message: data,
        type: "error",
      });

      return;
    }
    // const uif = await getUserInfo();

    const result = JSON.parse(data);
    if (result?.walletList) {
      result.walletList = updStringToDecimal(result.walletList);
    }
    console.log("用户信息", result);
    if (result.monthTypeMoney) {
      const adObj: any = {};
      Object.keys(result.monthTypeMoney).forEach((key) => {
        const item = result.monthTypeMoney[key];
        item.money = new Decimal(item.money);
        adObj[item.id] = item;
      });
      result.monthTypeMoney = adObj;
    }
    result.token = headers.token;
    result.account = loginForm.account;
    setUserInfo({
      monthTypeMoney: {},
      ...result,
      // walletList: updActType(result.walletList),
    });
    setLocUserInfo(result);
    getRecordList();
    // updALllPrice();
    // 预防直接从登录页进来
    // nav(-1);
    nav("/")
    // console.log("登录结果", result);
  }
  return (
    <>
      <div class="lc_ct">
        <div class="lc_box" id="formId">
          <input
            type="number"
            placeholder="手机号码"
            class="input input-bordered w-full btn-lg"
            id="account"
            onChange={(event: any) => {
              loginForm.account = event.target.value;
            }}
            onPaste={(event: any) => {
              loginForm.account = event.clipboardData.getData("Text");
            }}
          />
          <input
            type="number"
            placeholder="udid"
            class="input input-bordered w-full btn-lg"
            id="deviceId"
            onChange={(event: any) => {
              loginForm.deviceId = event.target.value;
            }}
            onPaste={(event: any) => {
              loginForm.deviceId = event.clipboardData.getData("Text");
            }}
          />
          <button class="btn btn-primary btn-lg w-full" on:click={handleLogin}>
            登陆
          </button>
        </div>
      </div>
    </>
  );
}
